/* Built-in imports */
import React, { useEffect, useState } from "react";

/* Third-party imports */
import PropTypes from "prop-types";
import { Grid, CircularProgress, Snackbar, Alert } from '@mui/material';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";

/* Internal imports */
import Button from "../../../../../DS/inputs/Button";
import MaroquinerieRemunerationSection from "./MaroquinerieRemunerationSection";
import MaroquinerieTarificationSection from "./MaroquinerieTarificationSection";
import { MOYENS_PAIEMENT } from '../../../../../../utils/constantes/US/MOYENSPAIEMENT';
import { NormalizeIban } from "../../../../../../common/Normalizers";
import { STATUT_LIASSE } from "../../../../../../common/Configuration";
import { URLS_MAROQUINERIE } from "../../../../../../utils/constantes/ROUTES/MAROQUINERIE";
import { YesButton } from "../../../../../form/Button";
import {envoiEmailBulletinAvantSignature} from "../../../../../../services/DossierService";


const MaroquinerieTarificationForm = ({ dossier, liasse, change, handleSubmit, pristine, invalid, submitting, customErrorIban }) => {
  const [loadingSendMail, setLoadingSendMail] = useState(false);
  const [notificationSendMail, setNotificationSendMail] = useState(null);
  useEffect(() => {
    const { tarification, compteDePrelevement } = dossier;
    if (compteDePrelevement) {
      change(MOYENS_PAIEMENT.IBAN, NormalizeIban(compteDePrelevement.iban));
      compteDePrelevement.bic && change(MOYENS_PAIEMENT.BIC, compteDePrelevement.bic);
    }
    if (tarification) {
      change('fraisDossier', tarification.fraisDossier);
      change('fraisEntree', tarification.fraisEntree + ' %');
      change('optionChargeFrais', tarification.optionChargeFrais.toString());
      change('ciblePrelevements', tarification.ciblePrelevements);
    }
    else {
      change('fraisDossier', 0);
      change('fraisEntree', '1 %');
      change('optionChargeFrais', 1);
    }
  }, [dossier]);

  const handleSendContractToCustomer = async () => {
    setLoadingSendMail(true);
    try {
      await envoiEmailBulletinAvantSignature(dossier.uid);
      setNotificationSendMail('success');
    } catch (error) {
      console.log(error);
      setNotificationSendMail('error');
    } finally {
      setLoadingSendMail(false);
    }
  };
  const handleCloseSnackbar = () => {
    setNotificationSendMail(null);
  };
  return (
    <form onSubmit={handleSubmit} className="formTunnel formTarification">
      <MaroquinerieTarificationSection />
      <MaroquinerieRemunerationSection customErrorIban={customErrorIban} />
      <Grid container spacing={2} className="buttonCreationContainer" >
        <Grid item xs={12}>
          <Button
              variant="contained"
              onClick={handleSendContractToCustomer}
              className="yes-button"
              disabled={loadingSendMail}
              sx={{
                '&.MuiButton-root.MuiButton-contained': {
                  backgroundColor: '#bf252b',
                  '&:hover': {
                    backgroundColor: 'rgba(117, 33, 39, 0.8);',
                    color: 'white',
                  },
                },
              }}
          >
            {loadingSendMail ? <CircularProgress color="inherit" className="ges-circular-progress" size={50} /> : `Envoyer une copie du bulletin au client`}
          </Button>
        </Grid>
        <Snackbar
            open={notificationSendMail !== null}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
        >
          {notificationSendMail === 'success' ? (
              <Alert onClose={handleCloseSnackbar} severity="success">
                Bulletin envoyé avec succès !
              </Alert>
          ) : notificationSendMail === 'error' ? (
              <Alert onClose={handleCloseSnackbar} severity="error">
                Échec de l'envoi du Bulletin. Veuillez réessayer.
              </Alert>
          ) : null}
        </Snackbar>
        <Grid item xs={6}>
          <YesButton text="Signer le contrat" disabled={ invalid || submitting || pristine } />
        </Grid>
      </Grid>
      <div className="buttonCreationContainer">
        {liasse?.statut === STATUT_LIASSE.SIGNEE && (
          <p>
            J’ai déjà signé mon contrat et je n’en ai pas modifié les paramètres :
            <Link to={URLS_MAROQUINERIE.FINALISATION}>{" j'envoie mes pièces justificatives."}</Link>
          </p>
        )}
      </div>
    </form>
  );
}

MaroquinerieTarificationForm.propTypes = {
  dossier: PropTypes.object.isRequired,
  liasse: PropTypes.object,
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  customErrorIban: PropTypes.string
}

// REDUX + REDUX FORM
const mapStateToProps = ({ tunnel }) => ({
  dossier: tunnel.dossier,
  liasse: tunnel.liasse
});

export default connect(mapStateToProps)(reduxForm({ form: 'MaroquinerieTarificationForm' })(MaroquinerieTarificationForm));
