/* Built-in imports */
import React, { Component } from 'react';

/* Third-party imports */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Internal imports */
import TarificationForm from '../../../../components/US/epargne_salariale/forms/tarification/TarificationForm';
import { FORMAT_INVALIDE } from '../../../../common/ApiError';
import { URLS_EPARGNE } from '../../../../utils/constantes/ROUTES/EPARGNE';
import { creerLiasseSouscription } from '../../../../services/LiasseService';
import { mettreAjourTarification } from '../../../../services/DossierService';
import { retirerPourcent, stringSansEspaces } from '../../../../common/FunctionsUtils';
import {
  setLiasse,
  setLoader,
  setRetourRoute,
  setTarification
} from '../../../../modules/tunnel/redux/tunnelActions';
import { redirectDocuSign } from '../../../../utils/routes/RoutesUtils';
import { MOYENS_PAIEMENT } from '../../../../utils/constantes/US/MOYENSPAIEMENT';

class EpargneTarificationPage extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.mapValues = this.mapValues.bind(this);
    // On modifie la route lorsque l'utilisateur clique le bouton Retour
    this.props.setRetourRoute(URLS_EPARGNE.KYC.substring(1));

    this.state = {};
  }

  mapValues = (values) => {
    let result = {
      iban: stringSansEspaces(values[MOYENS_PAIEMENT.IBAN]),
      bic: values[MOYENS_PAIEMENT.BIC],
      fraisDossier: values.fraisDossier,

      fraisEntree: retirerPourcent(values.fraisEntree.replace(',', '.')),
      applicationFraisConseil: values.applicationFraisConseil ? values.applicationFraisConseil : false,
      fraisConseil: values.applicationFraisConseil ? values.fraisConseil : null,
      optionChargeFrais: values.optionChargeFrais,
    };
    if (result.fraisEntree > 0) {
      result = {
        ...result,
        ciblePrelevements: values.optionChargeFrais === '1' ? values.ciblePrelevements : null,
        fraisTypeVersement:
          values.optionChargeFrais === '2'
            ? {
              interessement: values.interessement,
              participation: values.participation,
              versementCET: values.versementCET,
              versementsComplementaires: values.versementsComplementaires,
              versementsVolontaires: values.versementsVolontaires,
              versementPPV: values.versementPPV
            }
            : null,
        fraisRepartition:
          values.optionChargeFrais === '3'
            ? {
              repartitionBeneficiaire: values.repartitionBeneficiaire,
              repartitionEntreprise: values.repartitionEntreprise
            }
            : null
      }
    }
    return result;
  };

  // SUBMIT
  async submit(values) {
    const valuesAenvoye = this.mapValues(values);
    this.props.setLoader(true);

    try {
      const dossier = await mettreAjourTarification(this.props.uid, { valuesAenvoye });
      this.props.setTarification(dossier.tarification);
      const liasse = await creerLiasseSouscription({ uid: this.props.uid });
      this.props.setLiasse(liasse);
      this.props.setLoader(redirectDocuSign(liasse, window.location.origin + URLS_EPARGNE.FINALISATION));
    } catch (error) {
      if (error && error.message) {
        switch (error.code) {
          case FORMAT_INVALIDE:
            this.setState({
              errorMsg: error.message
            });
            break;
          default:
        }
      }
    }
  }

  render() {
    const { errorMsg } = this.state;

    return (
      <div>
        <TarificationForm onSubmit={this.submit} customErrorIban={errorMsg} />
      </div>
    );
  }
}

EpargneTarificationPage.propTypes = {
  setLiasse: PropTypes.func,
  setLoader: PropTypes.func,
  setRetourRoute: PropTypes.func,
  setTarification: PropTypes.func,
  tunnel: PropTypes.object
};

// REDUX + REDUX FORM
const mapStateToProps = ({
  tunnel: {
    dossier: { uid, type }
  }
}) => ({
  uid,
  type
});

const mapDispatchToProps = (dispatch) => ({
  setRetourRoute: (route) => dispatch(setRetourRoute(route)),
  setTarification: (tarification) => dispatch(setTarification(tarification)),
  setLoader: (actif) => dispatch(setLoader(actif)),
  setLiasse: (liasse) => dispatch(setLiasse(liasse)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EpargneTarificationPage);
