import {
    TYPE_VERSEMENT_TARIFICATION as TYPE_VERSEMENT
} from "../../../../../common/constants";


export const getVersementDisponible = (dossier) => {
    const {
        dispositifs: { dispositifPei, dispositifPercoi }
    } = dossier;

    const versements = [TYPE_VERSEMENT.VERSEMENT_VOLONTAIRE];
    if (
        (dispositifPei && dispositifPei.abondement) ||
        (dispositifPercoi && dispositifPercoi.abondement) ||
        (dispositifPercoi.abondementUnilateral?.miseEnPlace)
    ) {
        if (dispositifPei && dispositifPei.abondement) {
            dispositifPei.abondement.sourceVersements.forEach(versement => {
                if (!versements.includes(versement)) {
                    versements.push(versement);
                }
            })
        }
        if (dispositifPercoi && dispositifPercoi.abondement) {
            dispositifPercoi.abondement.sourceVersements.forEach(versement => {
                if (!versements.includes(versement)) {
                    versements.push(versement);
                }
            })
        }
        versements.push(TYPE_VERSEMENT.ABONDEMENT);
    }

    if (hasInteressement(dossier)) {
        versements.push(TYPE_VERSEMENT.INTERESSEMENT);
    }
    if (hasParticipation(dossier)) {
        versements.push(TYPE_VERSEMENT.PARTICIPATION);
    }

    return versements;
};

const hasInteressement =
  (dossier) => dossier?.interessement?.interessementActive || dossier?.planExistant?.accordInteressementEnPlace;

const hasParticipation =
  (dossier) => dossier?.participation?.participationActive || dossier?.planExistant?.accordParticipationEnPlace;
